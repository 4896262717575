import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { Collapse } from "react-collapse";

import {MenuChapter} from './MenuChapter'

import chevronRight from "img/svgs/chevron-right.svg";

class MenuItemCollapse extends Collapse {}

export function MenuItem(props) {
  const [IsOpen, setIsOpen] = useState(false);
  let newIsOpen;

  if (useLocation().pathname.indexOf(props.data.path) === 0 && props.mode === false) {
    newIsOpen = true;
  } else {
    newIsOpen = false;
  }
  
  if (newIsOpen !== IsOpen && props.mode === false) {
    setIsOpen(newIsOpen);
  }
  
  return (
    <>
      
        <div className="wrapper" key={props.data.path}>
          <div
            className={"chapter l" + props.level}
            onClick={() => {
              props.action(true)
              if (IsOpen === false) {
                setIsOpen(true);
              } else {
                setIsOpen(false);
              }
            }}
          >
            {props.level > 0 ? <div className="l-element blank-icon" /> : ""}
            {props.level > 1 ? <div className="l-element blank-icon" /> : ""}
            {props.level > 2 ? <div className="l-element blank-icon" /> : ""}
            <img
              className={"l-element " + (IsOpen ? "rotate down" : "rotate")}
              src={chevronRight}
              alt={ IsOpen ? "v" : ">" }
            />
            <span className="l-element">{props.data['label' + props.language]}</span>
          </div>
          <MenuItemCollapse isOpened={IsOpen}>
            {props.data.children.map((item,i) => (
              <div key={item.path}>
                { !(item.children?.length) &&
                  (item.visible === 1) &&
                  <MenuChapter
                    action={props.action}
                    onSetSidebarOpen={props.onSetSidebarOpen}
                    mode={props.mode}
                    data={item}
                    level={props.level + 1}
                    language={props.language}
                  />
                }
                { item.children?.length &&
                  (item.visible === 1) &&
                  <MenuItem
                    action={props.action}
                    onSetSidebarOpen={props.onSetSidebarOpen}
                    mode={props.mode}
                    data={item}
                    level={props.level + 1}
                    language={props.language}
                  />
                }
              </div>
            ))}
          </MenuItemCollapse>
        </div>

    </>
  );
}