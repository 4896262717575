import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18next";
import { Suspense } from "react";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App/>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
