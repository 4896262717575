import React from "react";
import { NavLink } from "react-router-dom";

export function MenuChapter(props){
  return (
    <div className="wrapper">
      <NavLink
        className={"chapter l" + props.level}
        exact
        to={props.data.path}
        onClick={() => {
          props.action(true);
          props.onSetSidebarOpen(false);
          }
        }
      >
        {props.level > 0 ? <div className="l-element blank-icon" /> : ""}
        {props.level > 1 ? <div className="l-element blank-icon" /> : ""}
        {props.level > 2 ? <div className="l-element blank-icon" /> : ""}
        <div className="l-element blank-icon" />
        <span className="l-element">{props.data['label' + props.language]}</span>
      </NavLink>
    </div>
  )
}