import React from "react";
import {MenuItem} from './MenuItem'
import {MenuChapter} from './MenuChapter'

export function Menu(props){
  return (
    <>
      {props.data.map((item,i) => (
        <div className="wrapper" key={item.path}>
          { !(item.children?.length) &&
            (item.visible === 1) &&
            <MenuChapter
              onSetSidebarOpen={props.onSetSidebarOpen}
              action={props.action}
              mode={props.mode}
              data={props.data[i]}
              level={0}
              language={props.language}
            />
          }
          { item.children?.length &&
            (item.visible === 1) &&
            <MenuItem
              onSetSidebarOpen={props.onSetSidebarOpen}
              action={props.action}
              mode={props.mode}
              data={props.data[i]}
              level={0}
              language={props.language}
            />
          }
        </div>
      ))}
    </>
  );
}