import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Collapse } from "react-collapse";
import Fuse from 'fuse.js';
import Highlighter from "react-highlight-words";

import cross from "./img/svgs/x.svg";
import search from "./img/svgs/search.svg";
import blank from "./img/svgs/blank.svg";

class MenuItemCollapse extends Collapse {}

export function SearchBox(props) {
  
  const { t } = useTranslation();
  const [IsOpen, setIsOpen] = useState(false)
  const [input, setInput] = useState('')
  let chaptersFull = useRef("")

  useEffect(() => {
    setInput("")
    chaptersFull.current = props.chapters.map(chapter => {
      let rChapter = {};
      rChapter["label"] = chapter['label-search'];
      rChapter["articleId"] = chapter.articleId + props.language;
      rChapter["path"] = chapter.path;
      fetch('/manual-pages/' + chapter.articleId + props.language + '.md')
        .then(response => response.text()) 
        .then(textString => {
          rChapter["content"] = textString.replace(/[*#]/g,"").replace(/!\[.+?\]\(.+?\)/g,"").replace(/<!--[\s\S]*?-->/g,"").replace(/\[(.+?)\]\(.+?\)/g,"$1").replace(/[-+] /g,"\u2022 "); 
        });
      return rChapter
    })
  }, [props.language, props.chapters]);

  var fuse = new Fuse(chaptersFull.current, {
    keys: [
      'content'
    ],
    includeMatches: true,
    threshold: 0,
    ignoreLocation: true,
    useExtendedSearch: true
  });

  if (input.length > 2) {
    var results = fuse.search("'\"" + input + "\"", {limit: 5});
  } else {
    results = fuse.search("'\"\"", {limit: 5});
  }

  if (IsOpen === false && input.length > 2 ) {
    setIsOpen(true); 
  }
  if (IsOpen === true && input.length <= 2 ) {
    setIsOpen(false); 
  }

  return(
    <div className="wrapper">
      <div
        className="chapter"
      >
        <img
          className={"l-element small-icon "}
          src={ input ? cross : blank }
          alt={ input ? "x" : "" }
          onClick={() => { setInput(""); }
          }
        />
        <img
          className="l-element small-icon"
          src={search}
          alt="Search"
        />
        <input className="input" value={input} onInput={e => setInput(e.target.value)}/>
      </div>
      <MenuItemCollapse isOpened={IsOpen}>
        {results.length === 0 ? <div className="no-result">The search returned no results</div> : "" }
          {results.map( chapter => {
            const { label, path, articleId, content } = chapter.item;
            var matchIndexes = chapter.matches.filter(x => x.key === 'content')[0].indices.slice(0,5)
            for (let i = 0; i < matchIndexes.length; i++ ) {
              matchIndexes[i][0] = content.lastIndexOf("\n" , matchIndexes[i][0]);
              matchIndexes[i][1] = content.indexOf("\n" , matchIndexes[i][1]);
              if ( matchIndexes[i][1] === -1) { matchIndexes[i][1] = content.length }
              if (i > 0) {
                if (matchIndexes[i-1][0] === matchIndexes[i][0]) {
                  i=i-1
                  matchIndexes.splice(i,1)
                }
              }
             }
            for (let i = 0; i < matchIndexes.length; i++ ) {
             if (matchIndexes[i][1] === -1) {
              i=i-1
              matchIndexes.splice(i,1)
              }
            }
            return (
              <NavLink
                key={articleId}
                className="search-result"
                to={path}
                onClick={() => {
                  props.action(false)
                  props.onSetSidebarOpen(false)
                }}
              >
                <div className="search-chapter"><strong> {t("chapter.t")}</strong>: {label}</div>
                <div className="search-link">{ matchIndexes.map( (matchIndex, i) => {
                  var resultString = content.substring(matchIndex[0], matchIndex[1])
                  return(
                    <span key={articleId + "-m" + i}>
                      <Highlighter
                        highlightClassName="search-highlight"
                        searchWords={[input]}
                        textToHighlight={resultString}
                      />
                      <br/>
                    </span>
                    )
                } )}</div>
              </NavLink>
            )
          })}
      </MenuItemCollapse>
    </div>
  )
}