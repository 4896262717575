import React from "react";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import {Route,BrowserRouter} from "react-router-dom"

import Sidebar from "react-sidebar";

import ManualPage from "./ManualPage"

import LangBtn from "./LangBtn"

import {Menu} from './menu/Menu'
import {SearchBox} from "./SearchBox"

import abilisLogo from "./img/svgs/abilis-logo-white.svg"
import itFlag from "./img/svgs/it.svg"
import gbFlag from "./img/svgs/gb.svg"
import manualIcon from "./img/svgs/book.svg"
import closeIcon from "./img/svgs/arrow-left.svg"
import antekLogo from "./img/svgs/antek.svg"

const mql = window.matchMedia(`(min-width: 800px)`);

function flat(r, a) {
  var b = {};
  Object.keys(a).forEach(function (k) {
      if (k !== 'children') {
          b[k] = a[k];
      }
  });
  if (a.articleId !== undefined) {
    r.push(b);
  }
  if (Array.isArray(a.children)) {
      b.children = a.children.map(function (a) { return a.id; });
      return a.children.reduce(flat, r);
  }
  return r;
}

function generateSearchHeaders(a, parentLabels, languageCode) {
  Object.keys(a).forEach(function (k) {
    a[k]['label-search'] = parentLabels + a[k]["label" + languageCode]
    if (Array.isArray(a[k].children)) {
      var childrenLabel = a[k]['label-search'] + " > "
      generateSearchHeaders(a[k].children, childrenLabel, languageCode)
    }
  });
  return a;
}

function ManualRoute(props) {
  return(
    <Route exact path={props.path} render={() => <ManualPage {...props} />} />
  )
}

class TranslatedApp extends React.Component {
  constructor(props) {
    super(props)
    this.langHandler = this.langHandler.bind(this);
    this.setManual = this.setManual.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.state = { 
      isManual: false,
      languageCode: "",
      chapters: undefined,
      chaptersTree: undefined,
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };
  }

  async fetchChapters() {
    const response = await fetch("/manual-pages/chapters-tree.json");
    const chaptersTree = await response.json();
    var chapters = generateSearchHeaders(chaptersTree,"",this.state.languageCode)
    chapters = chapters.reduce(flat, [])  

    this.setState({
      chapters,
      chaptersTree
    });
  }

  setManual(toValue) {
    if (toValue !== this.state.isManual) {
      this.setState({ isManual: toValue })
    }
  }
  
  onSetSidebarOpen(toValue) {
    this.setState({ sidebarOpen: toValue });
  }

  componentWillUnmount() {
    mql.removeEventListener("change", () => {
      this.mediaQueryChanged();
    });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  langHandler(code, i18nCode) {
    i18n.changeLanguage(i18nCode);
    if (code !== this.languageCode) {
      this.setState({ languageCode: code })
    }
    this.fetchChapters();
  }

  componentDidMount() {
    mql.addEventListener("change", () => {
       this.mediaQueryChanged();
    });
    
    this.fetchChapters();
  }

  render() {
    const { t } = this.props;
    console.log("Rendering manual...")
    return(
      <BrowserRouter>
        <div className="main manual">
          <Sidebar
            sidebar={
              <>
                <div className="manual-menu-antek-container">
                  <img src={antekLogo} className="manual-menu-antek-logo" alt="antek"/>
                </div>
                <div className="manual-menu-intro">
                  <div className="manual-menu-intro-head">
                    <img className="manual-menu-intro-logo" src={abilisLogo} alt="Abilis" />
                    <div className="manual-label">
                      {t("abilis-version.t")}
                    </div>
                  </div>
                  <div className="language-row">
                    <LangBtn action={this.langHandler} code="-it" i18nCode="it" label="Italiano" image={itFlag}/>
                    <LangBtn action={this.langHandler} code="" i18nCode="en" label="English" image={gbFlag}/>
                  </div>
                </div>
                <div className="manual-menu-chapters">
                  { this.state.chapters === undefined ? "" :
                    <SearchBox
                      language={this.state.languageCode}
                      chapters={this.state.chapters}
                      action={this.setManual}
                      onSetSidebarOpen={this.onSetSidebarOpen}
                    />
                  }
                  <div className="splitter"/>
                  { this.state.chaptersTree === undefined ? "" : 
                    <Menu
                      action={this.setManual}
                      onSetSidebarOpen={this.onSetSidebarOpen}
                      mode={this.state.isManual}
                      data={this.state.chaptersTree}
                      language={this.state.languageCode}
                    />
                  }
                </div>
              </>
            }
            sidebarClassName={"manual-menu"}
            open={this.state.sidebarOpen}
            docked={this.state.sidebarDocked}
            onSetOpen={this.onSetSidebarOpen}
            touch={false}
            shadow={false}
          >
          { mql.matches === true ? "" :
          <button className="menu-button" onClick={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}>
            { this.state.sidebarDocked === false && this.state.sidebarOpen === false ? <img src={manualIcon} alt="Menu"/> : "" }
            { this.state.sidebarDocked === false && this.state.sidebarOpen === true ? <img src={closeIcon} alt="Close"/> : "" }
          </button>
          }
          <div className="article-content">
          { this.state.chapters === undefined ? "" : this.state.chapters.map(chapter => {
              const { path, articleId } = chapter;
              return (
                <ManualRoute
                  key={path}
                  path={path}
                  articleId={articleId + this.state.languageCode}
                  langId={this.state.languageCode}
                  action={this.setManual}
                />
              )
            })}
          </div>
          </Sidebar>
        </div>
      </BrowserRouter>
    );
  }
}

const App = withTranslation()(TranslatedApp);
export default App;