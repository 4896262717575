import React, {Component} from "react";
import {Link} from "react-router-dom"

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default class ManualPage extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      currentArticle: this.props.articleId,
      currentLangId: this.props.langId,
      md: ''
    }
  }

  async componentDidMount() {
    const fileResponse = await fetch('/manual-pages/' + this.props.articleId + '.md');
    const text = await fileResponse.text();
    this.setState({
      currentArticle: this.props.articleId,
      currentLangId: this.props.langId,
      md: text,
    })
  }

  async componentDidUpdate() {
    console.log("Current chapter path: " + this.props.path)
    if (this.state.currentArticle !== this.props.articleId) {
      const fileResponse = await fetch('/manual-pages/' + this.props.articleId + '.md');
      const text = await fileResponse.text();
      this.setState({
        currentArticle: this.props.articleId,
        md: text,
      })
    }
  }
  
  render() {
    return (
      <div key={this.currentArticle} className="article">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={this.state.md}
          components={{
            a: ({...props}) => <Link onClick={() => this.props.action(false)} to={props.href}>{props.children}</Link>
          }}
        />
      </div>
    )
  }
}